<script setup lang="ts">

import {defaultAirport, defaultDate, flightSearchValidate, makeSearch} from "~/utils/flight/search-utils";
import type {FormSubmitEvent} from "#ui/types";
import type {FlightSearchDate} from "~/types/flight/search/FlightSearchDate";
import type {TripType} from "~/types/flight/search/TripType";
import {onErrorForm} from "~/utils/base/common-utils";
import type {CityAirport} from "~/types/flight/city/CityAirport";
import type {CabinType} from "~/types/flight/search/CabinType";
import type {FlightTypeItem} from "~/types/flight/FlightTypeItem";

const props = defineProps<{
    tripType?: TripType,
    origin?: CityAirport,
    destination?: CityAirport,
    cabinType?: CabinType,
    flightType?: FlightTypeItem
    hasRoute?: boolean,
    noPath?: boolean
}>()

const emit = defineEmits(['handleSearch'])

const state = reactive(makeSearch(props.origin ?? defaultAirport(), props.destination ?? defaultAirport(), defaultDate(), props.tripType, props.flightType, props.cabinType ?? null));
const showDatePiker = ref(false)
const showPassengersOptions = ref(false)
const dateInput = ref('')


watch(props, () => {
    if (props.tripType) {
        dateInput.value = ''
        Object.assign(state, makeSearch(props.origin ?? defaultAirport(), props.destination ?? defaultAirport(), defaultDate(), props.tripType))
    }
})


const handleOut = () => {
    showDatePiker.value = false
    showPassengersOptions.value = false
}

const handleSearch = async (event: FormSubmitEvent<any>) => {
    emit('handleSearch', state)
}

</script>

<template>
    <div class="w-full ">
        <u-form
            :state="state"
            :validate="flightSearchValidate"
            @error="onErrorForm"
            @submit="handleSearch"
            class="w-full grid grid-cols-10 justify-center items-center pt-2 pb-6 gap-2"
        >
            <Suspense>
                <lazy-flight-search-origin-destination-field-component
                    :state="state"
                    :has-route="props.hasRoute"
                    :no-path="props.noPath"
                />
            </Suspense>
            <lazy-flight-search-date-box-component
                :state="state"
                :show-date-piker="showDatePiker"
                :has-route="props.hasRoute"
                :no-path="props.noPath"
                @update:showDatePiker="(val: boolean) => showDatePiker = val"
                @update:date="(date: FlightSearchDate) => state.date = date"
            />
            <lazy-flight-search-passengers-input-box-component
                :state="state"
                :show-passengers-options="showPassengersOptions"
                :has-route="props.hasRoute"
                @update:showPassengersOptions="(val: boolean) => showPassengersOptions = val"
            />

            <div class="w-full col-span-1 flex items-center justify-center">
                <u-button
                    type="submit"
                    label="جستجو"
                    :color="hasRoute ? 'neutral' : 'primary'"
                    :variant="hasRoute ? 'outline' : 'solid'"
                    :ui="{base: `${hasRoute ? 'text-black' : '' } w-full flex justify-center py-3 rounded-full`}"
                />
            </div>
        </u-form>
    </div>

    <lazy-common-bg-black-cover-component
        v-if="showPassengersOptions || showDatePiker"
        bg-color="bg-transparent"
        @close="handleOut"
    />
</template>
